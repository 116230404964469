<template>
  <div class="pro home">
    <bloc-connexion v-if="!addeoSmall" :header="true" />
    <bloc-bandeau v-if="paramBandeauComm" />
    <bloc-header/>
    <bloc-avantages/>
    <bloc-inscription v-if="markOrientest" />
    <bloc-facilitateur v-if="0"/>
    <bloc-outils/>
    <bloc-outils-distance v-if="markOrientest" />
    <bloc-reseau/>
    <bloc-parcours-pro/>
    <bloc-grand-est/>
    <bloc-inscription :notop="true"/>
    <!-- <bloc-carousel/> -->
    <bloc-footer/>
  </div>
</template>


<script>
import Connexion from '@/components/Connexion.vue'
import BandeauComm from '@/components/BandeauComm.vue'
import Header from '@/components/Header.vue'
import Outils from '@/components/pro/Outils.vue'
import OutilsDistance from '@/components/pro/OutilsDistance.vue'
import Reseau from '@/components/Reseau.vue'
import Avantages from '@/components/pro/Avantages.vue'
import Facilitateur from '@/components/pro/Facilitateur.vue'
import ParcoursPro from '@/components/pro/ParcoursPro.vue'
import GrandEst from '@/components/pro/GrandEst.vue'
import Inscription from '@/components/pro/Inscription.vue'
// import Carousel from '@/components/Carousel.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Portail-Lorfolio-Pro',
        data() {
            return {
                tmp:true,
                accueil:true
            }
        },
        components: {
            'bloc-connexion': Connexion,
            'bloc-bandeau': BandeauComm,
            'bloc-header': Header,
            'bloc-outils': Outils,
            'bloc-outils-distance': OutilsDistance,
            'bloc-reseau': Reseau,
            'bloc-avantages': Avantages,
            'bloc-facilitateur': Facilitateur,
            'bloc-parcours-pro': ParcoursPro,
            'bloc-grand-est': GrandEst,
            'bloc-inscription': Inscription,
            // 'bloc-carousel': Carousel,
            'bloc-footer': Footer,
        },
/*
        mounted() {
            window.addEventListener("scroll", this.myEventHandlerScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.myEventHandlerScroll);
            this.$parent.addeoOnTop = true;
        },
*/
        computed:{
                addeoSmall() {
                    return(this.$parent.addeoSmall);
                },
                addeoMedium() {
                    return(this.$parent.addeoMedium);
                },
                addeoLarge() {
                    return(this.$parent.addeoLarge);
                },
                markOrientest() {
                    return(this.$router.options.markOrientest);
                },
                paramBandeauComm() {
                    return(this.$router.options.paramBandeauComm);
                }
        },
/*
        methods: {
            myEventHandlerScroll(e) {
                this.initialiserScroll();
            },
            initialiserScroll() {
                if (window.scrollY) {
                    this.$parent.addeoOnTop = false;
                }
                else {
                    this.$parent.addeoOnTop = true;
                }
            }
        },
*/
        beforeCreate() {
                this.$parent.sitePRO = true;
        }

}
</script>

<style lang="scss">
/*
html {
    scroll-behavior: smooth;
}
*/
</style>
