<template>
    <div id="facilitateur" v-bind:class="{markOrientest : markOrientest}">
        <div class="containerFond">
            <div class="gauche"></div>
            <div class="containerAffichage">

                <h2 class="sousTitre" v-if="markOrientest">
                    <span class="commeSousTitre">Expérimentez un compte Mon Orient'Est</span>
<!--
                    <span class="commeSousTitre">Accompagnez vos bénéficiaires</span>
                    <br/>
                    <img src="../../assets/img/header/logoMonOrientest.svg" alt="Mon OrientEst" class="centrer marqueOrientest" />
                    <span class="commeSousTitre"> avec le service</span><br/>
                    <span class="commeSousTitre"> facilitation </span>
                    <img  src="../../assets/img/pro/header/logoOrientestPro.svg" alt="OrientEst Pro" class="centrer marqueOrientest" />
-->
                </h2>

                <h2 class="sousTitre" v-if="!markOrientest">
                    <span class="commeSousTitre">Accompagnez les<br/>bénéficiaires avec Lorfolio PRO</span>
                </h2>

                <div  v-if="markOrientest && 0" class="consigne">
                    <div>Devenez Facilitateur ou Facilitatrice Lorfolio</div>
                    <router-link to="/rencontres-pro" class="bouton">Participer à une action de perfectionnement</router-link>
                </div>


                <div class="gaucheOE" v-if="markOrientest">
                    <img  src="../../assets/img/oe/pro/monOE.png" alt="OrientEst PRO" class="centrer marqueOrientest" />
                </div>

                <div class="droiteOE droite" v-if="markOrientest">
                    <p>
                        En tant que PRO, vous disposez d'un <span class="important">compte personnel</span> pour apprécier le compagnon du parcours professionnel <span class="important">Mon Orient'Est</span>.
                    </p>
                    <p>
                        Vous pouvez ainsi repérer tous les intérêts pour vos publics.
                    </p>
                </div>

                <div class="droite"  v-if="!markOrientest">
                    <p>
                        <i class='fa fa-star'></i>
                        Créez des groupes de bénéficiaires pour les mettre en réseau
                    </p>
                    <p>
                        <i class='fa fa-star'></i>
                        Invitez des bénéficiaires à rejoindre Lorfolio
                    </p>
                    <p>
                        <i class='fa fa-star'></i>
                        Consultez et commentez ce qu’un bénéficiaire autorise sur son compte
                    </p>
                    <p>
                        <i class='fa fa-star'></i>
                        Accompagnez à distance, de façon asynchrone, et même à plusieurs référents
                    </p>
                    <div class="consigne">Devenez Facilitateur ou Facilitatrice Lorfolio<router-link to="/rencontres-pro"><a class="bouton">Participer à une action de perfectionnement</a></router-link></div>
                </div>

                <div class="clear">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Avantages',
    components: {
        },
    props: {
    },
    data() {
        return {
            tmp:true
        }
    },

    computed: {
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

.addeoSmall {
    #facilitateur {
        .containerFond {
            .containerAffichage {
                .droite {
                    .consigne {
                        max-width: 98%;
                    }
                }
            }
        }
    }
}

.addeoMedium.addeoLargeLow {
    #facilitateur {
        .containerAffichage {width: 98%;
            max-width: 1260px;
        }
    }
}

html body #app.pro #facilitateur h2.sousTitre {
    /*background: no-repeat center bottom transparent url("~@/assets/img/pro/facilitateur/barre.svg");
    max-width: 800px;
    */
    margin:0 auto 1em;
}


.addeoMedium {
    #facilitateur {
        &.markOrientest {
            .containerFond {
                .containerAffichage {
                    max-width: 1000px;

                    .droiteOE {
                        width: 62%;
                        padding-top:2em;
                    }
                    .gaucheOE {
                        width: 38%;
                        max-width: 320px;
                    }
                }

            }
        }
    }
}

.addeoMedium, .addeoSmall {
@media screen and (max-width: 900px) {
    #facilitateur {
        &.markOrientest {
            .containerFond {
                .containerAffichage {
                    .gaucheOE {
                        display: none;
                    }
                    .droiteOE {
                        width: 100%;
                        float:none;
                    }
                }

            }
        }
    }
}
}

    #facilitateur {
        width:100%;
        height:auto;
        &:NOT(.markOrientest) {
            background: no-repeat center top #F4CE45 url("~@/assets/img/decoupage.png");
        }
        &.markOrientest {
            /*margin-top:6em;
            background-color: #E1F7F5;
            */

            .containerFond {
                min-height: 0;

                .consigne {
                    margin-bottom:2em;
                    a {
                        display:inline-block;
                        width: auto;
                    }
                }
                .centre {
                    padding-top: 2em;
                    text-align: left;
                    max-width: 630px;
                    margin:0 auto;
                    p {
                        font-size: 1.4em;
                        margin:0 0 0.8em 0;
                        padding-left: 2em;
                    }
                    .fa {
                        font-size:1.2em ;
                        margin-left:-1.8em;
                        float:left;
                    }
                }
                .gauche {
                    background-image: none;
                }
                .containerAffichage {
                    max-width: 1000px;

                    .gaucheOE, .droiteOE {
                        float:left;
                    }
                    .droiteOE {
                        width: 60%;
                        padding-top:4em;
                    }
                    .gaucheOE {
                        width: 40%;
                        max-width: 400px;
                        img {
                            width: 100%;
                        }
                    }
                }

            }
        }
        text-align: center;
        color: #4b3c8f;

        .clear {
            clear: both;
        }

        .containerFond {
            width:100%;
            height:auto;
            padding:4em 0 2em;
            min-height: 420px;

            .gauche {
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
                background: no-repeat left bottom transparent url("~@/assets/img/pro/facilitateur/illusFacilitateurGauche.png");
                min-height: 600px;
                @media screen and (max-width: 1799px) {
                        max-width: 30%;
                        background-position: right bottom;
                    }
                @media screen and (max-width: 1200px) {
                        display:none;
                    }
            }


            .containerAffichage {
                margin: 2% auto 0 !important;
                .important {
                    font-weight: bold;
                }

                @media screen and (min-width: 1800px) {
                    max-width: 1400px !important;
                }

                .droite {
                    float: right;
                    max-width: 920px;
                    text-align: left;
                    &.droiteOE {
                        @media screen and (max-width: 1799px) {
                            max-width: 800px;
                        }
                        @media screen and (max-width: 1200px) {
                            float:none;
                            margin:0 auto;
                            width:90% !important;
                            padding-top: 2em;

                            p {
                                padding-left: 0;
                                font-size:1.2em;
                            }
                        }
                    }
                    p {
                        font-size: 1.4em;
                        margin:0 0 0.8em 0;
                        padding-left: 2em;
                    }
                    .fa {
                        font-size:1.2em ;
                        margin-left:-1.8em;
                        float:left;
                    }
                    .consigne {
                        float: right;
                        text-align: right;
                        display: inline-block;
                        margin:1em 0;
                        max-width: 75%;
                        font-size:1.2em ;
                        /*font-style: italic; 2021/07*/
                        font-weight: 600;
                        a {
                            .important {
                                font-weight: bold;
                                text-decoration: underline !important;
                                color: #4b3c8f;

                                &:hover, &:focus {
                                    opacity:0.8;
                                }
                            }

                        }
                    }
                }
            }
        }
        a.bouton {
            display:inline-block;
            border: 1px solid #356A73;
            background-color: #356A73;
            color: rgba(255,255,255,0.8);
            padding: 0.6em 0.8em;
            font-size: 0.75em;
            font-weight: 500;
            margin-top:0.6em;

            &:hover, &:focus {
                /*opacity: 0.5;*/
                background-color: white;
                color: #356A73;
            }
        }

    }




</style>
