import { render, staticRenderFns } from "./ParcoursPro.vue?vue&type=template&id=a660fef4&scoped=true&"
import script from "./ParcoursPro.vue?vue&type=script&lang=js&"
export * from "./ParcoursPro.vue?vue&type=script&lang=js&"
import style0 from "./ParcoursPro.vue?vue&type=style&index=0&id=a660fef4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a660fef4",
  null
  
)

export default component.exports