<template>
  <div id="unOutil">
    <img class="illustration" :src="getImgUrl" :alt="data.itemsTxt[item]" />
    <span class="legende" v-if="!data.items[item]">
      <span v-html="txtItem"></span>
      <div class="fond cliquable">
        <i v-if="0" class="fa fa-hand-pointer-o"></i>
      </div>
      <div class="fond cliquable droite">
        <i v-if="0" class="fa fa-hand-pointer-o"></i>
      </div>
    </span>
    <transition name="slide-fade">
      <span class="legende titre" v-if="data.items[item]">
        <span v-html="txtItem"></span>
        <div class="fond cliquable">
          <i v-if="0" class="fa fa-hand-pointer-o"></i>
        </div>
        <div class="fond cliquable droite">
          <i v-if="0" class="fa fa-hand-pointer-o"></i>
        </div>
      </span>
    </transition>
    <!--
  <transition name="slide-fade">
                <div class="contenu" v-if="data.items[item]">
                    <div v-html="data.itemsCont[item]"></div>
                </div>
  </transition>
-->
    <img
      v-if="!markOrientest"
      class="fond"
      src="../../../assets/img/pro/outils/cadreNormal.png"
      alt=""
    />
    <img
      v-if="!markOrientest"
      class="fond survol"
      src="../../../assets/img/pro/outils/cadreSurvol.png"
      alt=""
    />
    <img
      v-if="markOrientest"
      class="fond"
      src="../../../assets/img/oe/pro/outils/cadreNormal.png"
      alt=""
    />
    <img
      v-if="markOrientest"
      class="fond survol"
      src="../../../assets/img/oe/pro/outils/cadreSurvol.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Outil",
  props: {
    data: {
      type: Object,
    },
    item: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tmp: true,
    };
  },
  computed: {
    txtItem() {
      return this.data.itemsTxt[this.item] + this.iconeOpenClose;
    },
    iconeOpenClose() {
      if (!this.isOpen) {
        return '<i class="fa fa-angle-down"></i>'
      }
      return '<i class="fa fa-angle-up"></i>'
    },
    getImgUrl() {
      if (this.$parent.addeoSmall) {
        return "/" + this.data.imagesS[this.item];
      }
      return "/" + this.data.images[this.item];
    },
    markOrientest() {
      return this.$router.options.markOrientest;
    },
  },
};
</script>
