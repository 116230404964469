<template>
    <div id="avantage">

        <div class="item">
                <img class="illustration" :src="getImgUrl" :alt="data.itemsTitre[item]" />
                <div class="contenu" >
                    <span v-html="data.itemsCont[item]"></span>
                    <span v-if="data.liensOrientest[item]=='actionsTMP'"><a href="#parcoursPro">en savoir plus</a></span>
                    <span v-if="data.liensOrientest[item]=='accompagnerTMP'"><a href="#outils">en savoir plus</a></span>
                    <span v-if="data.liensOrientest[item]=='communauteTMP'"><a href="#grandEst">en savoir plus</a></span>
                    <span v-if="data.liensOrientest[item]=='actions'"><a href="javascript:;" @click="scrollToParcours">en savoir plus</a></span>
                    <span v-if="data.liensOrientest[item]=='accompagner'"><a href="javascript:;" @click="scrollToOutils">en savoir plus</a></span>
                    <span v-if="data.liensOrientest[item]=='communaute'"><a href="javascript:;" @click="scrollToGrandEst">en savoir plus</a></span>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Avantage',
        props: {
            data: {
                type: Object
            },
            item: {
                type: String
            }
        },
    data() {
        return {
            tmp:true
        }
    },
    computed: {
        getImgUrl() {
            var image = "/"+this.data.images[this.item];
            //var toto = require(image);
            //return imageName ? toto : ''
            return  image
        }
    },
    methods: {
        //this.$router.options.paramConnexionFixed
            scrollToParcours() {
                var elm = document.getElementById("parcoursPro")
                //elm.scrollIntoView({ behavior: 'smooth', block: 'start' });
                window.scrollTo({ top: elm.offsetTop, behavior: 'smooth' });
            },
            scrollToOutils() {
                var elm = document.getElementById("outils")
                //elm.scrollIntoView({ behavior: 'smooth', block: 'start' });
                window.scrollTo({ top: elm.offsetTop, behavior: 'smooth' });
            },
            scrollToGrandEst() {
                var elm = document.getElementById("grandEst")
                //document.getElementById('grandEst').scrollIntoView({ behavior: 'smooth', block: 'start' });
                window.scrollTo({ top: elm.offsetTop, behavior: 'smooth' });
            }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #avantage {


    }
</style>
