<template>
  <div id="grandEst" ref="grandEst" v-bind:class="{markOrientest : markOrientest}">
        <div class="containerAffichage">

      <h2 class="sousTitre">
        <span class="commeSousTitre">Les Rencontres PRO</span>
      </h2>
      <h3>
        Formez-vous à Orient'Est PRO et Mon Orient'Est
      </h3>

            <div class="items" v-if="markOrientest">
                <div class="item itemSimple">
                    <div v-if="0" class="nouveauTitre">
                        Découvrir
                    </div>
                    <div class="nouveauContenu">
                        <div class="image">
                            <img src="../../assets/img/pro/grandest/jumelles.svg" alt="Lorfolio" class="contenu" />
                        </div>
                        <div class="contenu">
                            <h4>Découverte <span class="insecable">Orient’Est PRO</span></h4>
                            <span>pour maîtriser<br/>Orient’Est PRO et<br/>explorer Mon Orient’Est</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div class="item itemCentre">
                    <div v-if="0" class="nouveauTitre">
                        Accompagner
                    </div>
                    <div class="nouveauGauche">
                        <div class="nouveauContenu">
                            <div class="image">
                                <img src="../../assets/img/pro/grandest/loupe.svg" alt="Lorfolio" class="contenu" />
                            </div>
                            <div class="contenu">
                                <h4>Perfectionnement <span class="insecable">Mon Orient’Est</span></h4>
                                <span>pour approfondir les<br/>caractéristiques et les usages<br/>des comptes Bénéficiaire</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                    <div class="nouveauDroite">
                        <div class="nouveauContenu">
                            <div class="image">
                                <img src="../../assets/img/pro/grandest/enLigne.svg" alt="Lorfolio" class="contenu large" />
                            </div>
                            <div class="contenu">
                                <h4>Facilitation en ligne<span class="insecable">Mon Orient’Est</span></h4>
                                <span>pour apprivoiser les outils<br/>d’accompagnement<br/>en ligne</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="item itemSimple">
                    <div v-if="0" class="nouveauTitre">
                        Améliorer
                    </div>
                    <div class="nouveauContenu">
                        <div class="image">
                            <img src="../../assets/img/pro/grandest/cafe.svg" alt="Lorfolio" class="contenu" />
                        </div>
                        <div class="contenu">
                            <h4>Cafés<br/><span class="insecable">Mon Orient’Est</span></h4>
                            <span>pour la communauté des<br/>pros qui accompagnent<br/>avec Mon Orient’Est</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
            <div class="items" v-if="!markOrientest">
                <div class="item">
                    <div class="">
                        <img src="../../assets/img/pro/grandest/decouverte.svg" alt="Lorfolio" class="contenu" />
                        <div class="contenu">
                            <h4>Découverte Lorfolio</h4>
                            <span>pour apprécier<br/>Lorfolio PRO</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div class="item">
                    <div class="">
                            <img src="../../assets/img/pro/grandest/animation.svg" alt="Lorfolio" class="contenu" />
                        <div class="contenu">
                            <h4>Perfectionnement Lorfolio</h4>
                            <span>pour maîtriser<br/>Lorfolio PRO</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div class="item">
                    <div class="">
                        <img src="../../assets/img/pro/grandest/cafe.svg" alt="Lorfolio" class="contenu" />
                        <div class="contenu">
                            <h4>Café Lorfolio</h4>
                            <span>pour la communauté<br/>des pros de Lorfolio</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
            <router-link v-if="0" to="/rencontres-pro" class="bouton">Voir les dates</router-link>
        </div>
  </div>
</template>

<script>
export default {
    name: 'Inscription',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },

    computed: {
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

html body #app.pro {
    #grandEst h2.sousTitre {
/*
        background: unset;
*/
        img, span {
            vertical-align: middle;
            font-size:90%;
        }
        img {
            width: 220px;
            max-width: 30%;
            margin-right: 1em;
        }
        margin:0 auto 0.6em;
        /*padding: 0.8em 0 1.2em;*/
        padding: 0.8em 0 0.5em;
    }
    &.addeoSmall {
        #grandEst h2.sousTitre {
            img {
                max-width: 50%;
            }
            img, span {
                font-size:6vw;
            }
        }


        #grandEst {
            &.markOrientest {
                .containerAffichage {

                    h3 {
                        font-size: 1.1em;
                        width: 90%;
                        max-width: 500px;
                    }
                }
            }
        }
    }
}


    .addeoMedium:not(.addeoLargeLow), .addeoSmall {
        #grandEst {
            .containerAffichage {
                .items {
                    .item {
                        width:100%;
                        margin-bottom : 3em;
                        &:last-of-type {
                            margin-bottom : 0;
                        }
                        > div {
                            max-width: 380px;
                            text-align: center;
                            .contenu {
                                width:100%;
                                margin:0 auto;
                                text-align: center;
                                float:none;
                            }
                            img {
                                max-width: 70px;
                            }
                        }
                    }
                }
            }
        }
    }

html body #app.pro {
    #grandEst {

        height:auto;
        background: no-repeat center bottom #F4CE45 none;
        width:90%;
        padding:1em 5% 0;
        color: #4B3C8F;
        z-index:1;

        &.markOrientest {
            padding-bottom:4em;
        width:100%;
        padding:1em 0 0;
            /*
            margin-top:0;
            padding-top:4em;
            padding-bottom:2em;
            */
            background-color: transparent;
/*
            h2.sousTitre {
                .commeSousTitre {
                    background: #4C3D8F;
                    color: #fff;
                    padding: 0px 8px;
                }
                img {
                    width: 275px;
                    max-width: 90%;
                    margin: 0.4em 0 0;
                }
            }
*/
            .containerAffichage {
                max-width: 100%;

                .clear {
                    width:100%;
                }

                h2.sousTitre {
                    width: 98%;
                    max-width: 1260px;
                }

                h3 {
                    color: #356a72;
                    font-size: 150%;
                    max-width: 680px;
                    margin: 3em auto 2em;
                }

                .items {
                    max-width: 1200px;
                    .item {
                        width:27%; /* 4 colonnes en 3 */
                        min-height: 370px;
                        .nouveauTitre {
                            width:90%;
                            font-size:1.7em;
                            font-weight: bold;
                            margin-bottom:1em;
                            padding-bottom:1em;
                            background-size: contain !important;
                        }
                        .nouveauGauche, .nouveauDroite {
                            width:50%;
                        }
                        .nouveauGauche {
                            float:left;
                        }
                        .nouveauDroite {
                            float:right;
                        }
                        .nouveauContenu {
                            .image {
                                min-height: 116px;
                                vertical-align: middle;
                                img {
                                    margin: 0 auto;
                                    max-width: 110px;
                                    max-height: 100px;
                                    &.large {
                                        max-width: 120px;
                                        max-height: 110px;
                                    }
                                }
                            }
                        }
                        &.itemCentre {
                            width:46%; /* 4 colonnes en 3 */
                            padding:4em 0 0;
                            background: no-repeat center top transparent url("~@/assets/img/oe/pro/grandest/cadreLarge.png");
                            background-size: auto;
                        }
                        &.itemSimple {
                            padding:4em 0 0;
                            background: no-repeat center top transparent url("~@/assets/img/oe/pro/grandest/cadre.png");
                            background-size: auto;
                        }
                        h4 {
                            line-height: 1em;
                            margin-top:0.6em;
                        }
                        &:first-of-type {
                            .nouveauTitre {
                                background: no-repeat center bottom transparent url("~@/assets/img/pro/grandest/gauche.png");
                            }
                        }
                        &:nth-of-type(2) {
                            .nouveauTitre {
                                background: no-repeat center bottom transparent url("~@/assets/img/pro/grandest/centre.png");
                            }
                        }
                        &:nth-of-type(3) {
                            .nouveauTitre {
                                background: no-repeat center bottom transparent url("~@/assets/img/pro/grandest/droite.png");
                            }
                        }

                        @media screen and (max-width: 1200px) {
                            display:block;
                            width:94%;
                            /*max-width: 260px;
                            margin:2em auto 4em;*/
                            max-width: 280px;
                            margin:1em auto 0em;

                            &.itemCentre {
                                width:100%;
                                /*max-width: 600px;*/
                                max-width: 540px;
                            }
                        }
                        @media screen and (max-width: 600px) {
                            min-height:300px;
                            margin-top:4em;
                            &.itemCentre {
                            background: no-repeat center top transparent url("~@/assets/img/oe/pro/grandest/cadreHaut.png");
                                .nouveauGauche, .nouveauDroite {
                            min-height:300px;
                            padding:1em 0 2em;
                            background-size: auto;
                                    width:100%;
                                    float:none;
                                }
                            }
                        }
                    }

                }
                a.bouton {
                    text-transform: uppercase;
                    font-size:1.0em;
                    font-weight: 500;
                }
            }
        }

        .containerAffichage {
            background: no-repeat center bottom transparent url("~@/assets/img/pro/facilitateur/barre.svg");
            background-position: bottom 15px center;
            margin-bottom: 0 !important;
            text-align: center;

                        a.bouton {
                            display:inline-block;
                            /*
                            border: 1px solid #EDF2F5;
                            background-color: #EDF2F5;
                            */
                            border: 1px solid #356A73;
                            background-color: #356A73;
                            color: rgba(255,255,255,0.8);
                            padding: 0.6em 0.8em;
                            font-size: 0.8em;
                            font-weight: 500;
                            border-radius: 0px;

                            &:hover, &:focus {
                                background-color: white;
                                color: #356A73;
                            }
                        }

            .items {
                /*max-width: 1200px;*/ /* 3 colonnes */
                max-width: 900px;
                width:100%;
                padding: 1em 0 2em;
                margin:0 auto;

                .item {
                    width:33%; /* 3 colonnes */
                    /*width:50%; 2 colonnes*/
                    display: inline-block;
                    vertical-align: bottom;
                    text-align: center;

                    > div {
                        margin:0 auto;
                        text-align: center;
                        display: inline-block;

                        .contenu {
                            /*width:50%;*/
                            /*float:left;*/
                        }
                        img {
                            max-width: 100px;
                            /*float:left;*/
                            margin-left:2em;
                            /*margin-right:2em;*/
                            margin:0 2em 0.8em;
                        }
                        span {
                            display: block;
                            margin:0.2em 0 1em;
                        }

                        h4 {
                            text-transform: none;
                        }
                    }
                }
            }

        }
    }
}
</style>
