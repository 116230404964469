<template>
  <div id="outils" ref="outils" v-bind:class="{ markOrientest: markOrientest }">
    <div class="containerAffichage">
      <h2 class="sousTitre">
        <span class="commeSousTitre">Accompagnez vos bénéficiaires</span>
      </h2>
      <h3>
        Proposez des comptes Mon Orient'Est à votre public et enrichissez vos
        missions
      </h3>
      <div id="items">
        <div class="item" id="passeport" @click.stop="reinit('passeport')">
            <span id="passeport0" class="pourScroll"></span>
          <item-outil
            :isOpen="items.passeport"
            :data="lesDatas"
            item="passeport"
          />
        </div>
        <div id="passeport1" v-if="itemCourant != 'passeport' && addeoSmall">
          <div
            id="passeport2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['passeport']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenu1"
            v-if="itemCourant == 'passeport' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="passeport1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div class="item" id="portfolio" @click.stop="reinit('portfolio')">
            <span id="portfolio0" class="pourScroll"></span>
          <item-outil
            :isOpen="items.portfolio"
            :data="lesDatas"
            item="portfolio"
          />
        </div>
        <div id="portfolio1" v-if="itemCourant != 'portfolio' && addeoSmall">
          <div
            id="portfolio2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['portfolio']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenu1"
            v-if="itemCourant == 'portfolio' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="portfolio1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div class="item" id="fenetre" @click.stop="reinit('fenetre')">
            <span id="fenetre0" class="pourScroll"></span>
          <item-outil :isOpen="items.fenetre" :data="lesDatas" item="fenetre" />
        </div>
        <div id="fenetre1" v-if="itemCourant != 'fenetre' && addeoSmall">
          <div
            id="fenetre2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['fenetre']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenu1"
            v-if="itemCourant == 'fenetre' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="fenetre1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div
          id="contenu2"
          class="contenu contenu3"
          v-if="!voirContenu2 && !addeoSmall"
        >
          <div
            id="contenu3"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['passeport']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div id="contenu2" class="contenu contenu3 avecBarre" v-if="voirContenu2">
            <div class="barre">
              <transition name="slideX-fade">
                <div
                  id="contenu3"
                  class="html"
                  v-if="itemCourant == itemTmp"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </transition>
            </div>
          </div>
        </transition>

        <div class="item haut" id="pepiniere" @click.stop="reinit('pepiniere')">
            <span id="pepiniere0" class="pourScroll"></span>
          <item-outil
            :isOpen="items.pepiniere"
            :data="lesDatas"
            item="pepiniere"
          />
        </div>
        <div id="pepiniere1" v-if="itemCourant != 'pepiniere' && addeoSmall">
          <div
            id="pepiniere2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['pepiniere']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenu1"
            v-if="itemCourant == 'pepiniere' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="pepiniere1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div
          class="item haut"
          id="ressources"
          @click.stop="reinit('ressources')"
        >
            <span id="ressources0" class="pourScroll"></span>
          <item-outil
            :isOpen="items.ressources"
            :data="lesDatas"
            item="ressources"
          />
        </div>
        <div id="ressources1" v-if="itemCourant != 'ressources' && addeoSmall">
          <div
            id="ressources2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['ressources']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenu1"
            v-if="itemCourant == 'ressources' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="ressources1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div class="item haut" id="coffre" @click.stop="reinit('coffre')">
            <span id="coffre0" class="pourScroll"></span>
          <item-outil :isOpen="items.coffre" :data="lesDatas" item="coffre" />
        </div>
        <div id="coffre1" v-if="itemCourant != 'coffre' && addeoSmall">
          <div
            id="coffre2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['coffre']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenu1"
            v-if="itemCourant == 'coffre' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="coffre1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <!--
<transition name="slideX-fade">
                <div id="contenu3" class="contenu contenu3" v-if="voirContenu3">
                    <div class="barre">
<transition name="slideX-fade">
                        <div class="html" v-if="itemCourant == itemTmp" v-html="lesDatas.itemsCont[itemCourant]"></div>
</transition>
                    </div>
                </div>
</transition>
-->
      </div>
    </div>
  </div>
</template>

<script>
import Outil from "@/components/pro/outil/Outil.vue";
export default {
  name: "Outils",
  components: {
    "item-outil": Outil,
  },
  props: {},
  data() {
    return {
      myTimeout: null,
      myTimeout2: null,
      itemArchive: "",
      itemTmp: "",
      itemCourant: "",
      images: {
        passeport: "img/pro/outils/imagePasseport.jpg",
        portfolio: "img/pro/outils/imagePortfolio.jpg",
        pepiniere: "img/pro/outils/imagePepiniere.jpg",
        fenetre: "img/pro/outils/imageFenetre.jpg",
        ressources: "img/pro/outils/imageRessources.jpg",
        coffre: "img/pro/outils/imageCoffre.jpg",
      },
      imagesS: {
        passeport: "img/pro/outils/imagePasseport.jpg",
        portfolio: "img/pro/outils/imagePortfolio.jpg",
        pepiniere: "img/pro/outils/imagePepiniereS.jpg",
        fenetre: "img/pro/outils/imageFenetre.jpg",
        ressources: "img/pro/outils/imageRessources.jpg",
        coffre: "img/pro/outils/imageCoffreS.jpg",
      },
      imagesMarkOrientest: {
        passeport: "img/oe/pro/outils/imagePasseport.png",
        portfolio: "img/oe/pro/outils/imagePortfolio.jpg",
        pepiniere: "img/oe/pro/outils/imagePepiniere.jpg",
        fenetre: "img/oe/pro/outils/imageFenetre.jpg",
        ressources: "img/oe/pro/outils/imageRessources.jpg",
        coffre: "img/oe/pro/outils/imageCoffre.jpg",
      },
      imagesSMarkOrientest: {
        passeport: "img/oe/pro/outils/imagePasseport.png",
        portfolio: "img/oe/pro/outils/imagePortfolio.jpg",
        pepiniere: "img/oe/pro/outils/imagePepiniereS.jpg",
        fenetre: "img/oe/pro/outils/imageFenetre.jpg",
        ressources: "img/oe/pro/outils/imageRessources.jpg",
        coffre: "img/oe/pro/outils/imageCoffreS.jpg",
      },
      items: {
        passeport: false,
        portfolio: false,
        pepiniere: false,
        fenetre: false,
        ressources: false,
        coffre: false,
      },
      itemsTxt: {
        passeport: "Avec le passeport<br/>professionnel",
        portfolio: "Avec le e-portfolio<br/>de compétences",
        fenetre: "Avec les outils<br/>dédiés à l’emploi",
        pepiniere: "Avec la pépinière de<br/>projets professionnels",
        ressources: "Avec un<br/>espace ressources",
        coffre: "Avec un<br/>porte-document",
      },
      itemsCont: {
        passeport:
          "<p><i class='fa fa-star'></i>Gagnez du temps dans la connaissance du parcours de vos publics</p><p><i class='fa fa-star'></i>Appuyez-vous sur une trame confirmée pour décrire le parcours</p><p><i class='fa fa-star'></i>Garantissez la qualité de la présentation du parcours</p>",
        /*
                "portfolio":"<p><i class='fa fa-star'></i>Utilisez un format de compétences éprouvé</p><p><i class='fa fa-star'></i>Identifiez les compétences développées</p><p><i class='fa fa-star'></i>Suivez l’évolution du bénéficiaire</p><p><i class='fa fa-star'></i>Examinez les prérequis pour démarrer une formation ou personnaliser un parcours de formation</p><p><i class='fa fa-star'></i>Aidez au repérage des acquis de l’expérience</p><p><i class='fa fa-star'></i>Favorisez l’association d’éléments de preuves",
*/
        portfolio:
          "<p><i class='fa fa-star'></i>Utilisez un format éprouvé de description des compétences</p><p><i class='fa fa-star'></i>Repérez les compétences développées par vos publics</p><p><i class='fa fa-star'></i>Associez les compétences avec des éléments de preuve</p><p><i class='fa fa-star'></i>Aidez au repérage des acquis de l’expérience</p><p><i class='fa fa-star'></i>Appréciez les prérequis pour une prochaine étape du parcours</p>",
        pepiniere:
          "<p><i class='fa fa-star'></i>Vérifiez l'avancement de chaque bénéficiaire grâce à un tableau de bord</p><p><i class='fa fa-star'></i>Consultez le journal de tous les messages de chaque compte que vous suivez</p><p><i class='fa fa-star'></i>Recevez des notifications à chaque apport</p>",
        fenetre:
          "<p><i class='fa fa-star'></i>Bénéficiez d’un éditeur de qualité pour guider vos publics dans la création de CV</p><p><i class='fa fa-star'></i>Accompagnez vos publics dans la création de leurs <a class='lienOutil' target='_blank' href='http://webfolios.lorfolio.fr'>webfolios</a> avec un puissant éditeur dédié</p><p><i class='fa fa-star'></i>Aidez-vous des outils intégrés pour sensibiliser vos publics à la e-reputation</p>",
        ressources:
          "<p><i class='fa fa-star'></i>Bénéficiez de ressources utiles pour former et accompagner</p><p><i class='fa fa-star'></i>Accédez à une banque de ressources sélectionnées pour compléter l’accompagnement de vos publics</p>",
        coffre:
          "<p><i class='fa fa-star'></i>Donnez la possibilité à vos publics de collecter et sécuriser tous leurs documents utiles</p><p><i class='fa fa-star'></i>Conservez les traces de vos travaux et de ceux de vos publics</p>",
      },
      itemsContMarkOrientest: {
        passeport:
          "<p><i class='fa fa-star'></i>Gagnez du temps dans la connaissance du parcours de vos publics</p><p><i class='fa fa-star'></i>Appuyez-vous sur une trame confirmée pour décrire le parcours</p><p><i class='fa fa-star'></i>Garantissez la qualité de la présentation du parcours</p>",
        portfolio:
          "<p><i class='fa fa-star'></i>Utilisez un format éprouvé de description des compétences</p><p><i class='fa fa-star'></i>Repérez les compétences développées par vos publics</p><p><i class='fa fa-star'></i>Associez les compétences avec des éléments de preuve</p><p><i class='fa fa-star'></i>Aidez au repérage des acquis de l’expérience</p><p><i class='fa fa-star'></i>Appréciez les prérequis pour une prochaine étape du parcours</p>",
        pepiniere:
          "<p><i class='fa fa-star'></i>Accompagnez l’émergence et la mise en œuvre des projets de vos publics à l’aide d’outils innovants et facilitants</p><p><i class='fa fa-star'></i>Attestez des progrès et des qualités grâce notamment aux open badges</p>",
        fenetre:
          "<p><i class='fa fa-star'></i>Bénéficiez d’un éditeur de qualité pour guider vos publics dans la création de CV</p><p><i class='fa fa-star'></i>Accompagnez vos publics dans la création de leurs <a class='lienOutil' target='_blank' href='@@webfolios@@'>webfolios</a> avec un puissant éditeur dédié</p><p><i class='fa fa-star'></i>Aidez-vous des outils intégrés pour sensibiliser vos publics à la e-reputation</p>",
        ressources:
          "<p><i class='fa fa-star'></i>Bénéficiez de ressources utiles pour former et accompagner</p><p><i class='fa fa-star'></i>Accédez à une banque de ressources sélectionnées pour compléter l’accompagnement de vos publics</p>",
        coffre:
          "<p><i class='fa fa-star'></i>Donnez la possibilité à vos publics de collecter et sécuriser tous leurs documents utiles</p><p><i class='fa fa-star'></i>Conservez les traces de vos travaux et de ceux de vos publics</p>",
      },
      tmp: true,
    };
  },

  created() {
    this.itemTmp = "";
  },

  computed: {
    voirContenu2() {
      return (
        (this.itemCourant != "" || this.itemTmp != "") &&
        (this.$parent.$parent.addeoLarge || this.addeoMedium)
      );
    },
    voirContenu3() {
      return (
        (this.itemCourant != "" || this.itemTmp != "") &&
        (this.$parent.$parent.addeoLarge || this.addeoMedium)
      );
    },
    lesDatas() {
      var datas = new Object();
      if (!this.markOrientest) {
        datas.images = this.images;
        datas.imagesS = this.imagesS;
        datas.itemsCont = this.itemsCont;
      } else {
        datas.images = this.imagesMarkOrientest;
        datas.imagesS = this.imagesSMarkOrientest;
        datas.itemsCont = this.itemsContMarkOrientest;
        var urlFolios = "https://webfolios.grandtest.addeo.ovh";
        if (this.$parent.$parent.isPreProdApp) {
          urlFolios = "https://preprod.webfolios.orientest.fr";
        } else {
          if (this.$parent.$parent.isProdApp) {
            urlFolios = "https://webfolios.orientest.fr";
          }
        }
        datas.itemsCont.fenetre = datas.itemsCont.fenetre.replace(
          "@@webfolios@@",
          urlFolios
        );
      }
      datas.items = this.items;
      datas.itemsTxt = this.itemsTxt;
      return datas;
    },
    addeoSmall() {
      return this.$parent.$parent.addeoSmall;
    },
    addeoMedium() {
      return this.$parent.$parent.addeoMedium;
    },
    addeoLarge() {
      return this.$parent.$parent.addeoLarge;
      //return(this.$parent.$parent.addeoLarge && !this.$parent.$parent.addeoXLarge);
    },
    markOrientest() {
      return this.$router.options.markOrientest;
    },
    /*
        addeoXLarge() {
            return(this.$parent.$parent.addeoLarge && this.$parent.$parent.addeoXLarge);
        }
        */
  },
  methods: {
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    isInViewport2(element1, element2) {
      const rect = element1.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      var h2 = rect2.bottom - rect2.top;
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect2.top + h2 <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect2.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    reinit(item) {
      item !== "passeport" ? (this.items["passeport"] = false) : null;
      item !== "portfolio" ? (this.items["portfolio"] = false) : null;
      item !== "pepiniere" ? (this.items["pepiniere"] = false) : null;
      item !== "fenetre" ? (this.items["fenetre"] = false) : null;
      item !== "ressources" ? (this.items["ressources"] = false) : null;
      item !== "coffre" ? (this.items["coffre"] = false) : null;

      this.items[item] = !this.items[item];

      this.itemTmp = item;
        this.itemArchive = item;
      if (this.itemCourant == "") {
        if (this.addeoSmall) {
          this.myTimeout2 = setTimeout(this.sePlacerSurItemS, 250);
        } else {
          this.changerItemCourant();
        }
      } else {
        this.itemCourant = "";

        if (this.addeoSmall) {
          this.myTimeout2 = setTimeout(this.sePlacerSurItemS, 250);
        } else {
          this.myTimeout = setTimeout(this.changerItemCourant, 250);
        }
      }

      // en dernier car on a pu refermer au dessus ...
      if (item !== "") {
        if (this.addeoLarge || this.addeoMedium) {
          this.myTimeout2 = setTimeout(this.sePlacerSurItemX, 500);
        }
      }
    },
    changerItemCourant() {
      if (this.myTimeout) {
        clearTimeout(this.myTimeout);
      }
      if (this.items[this.itemTmp]) {
        this.itemCourant = this.itemTmp;
      } else {
        this.itemCourant = "";
        this.itemTmp = "";
      }
    },
    sePlacerSurItemS() {
      if (this.myTimeout2) {
        clearTimeout(this.myTimeout2);
      }
      var element_to_scroll_to = document.getElementById(this.itemTmp);
      var element_to_validate1 = document.getElementById(this.itemTmp + "1");
      var element_to_validate2 = document.getElementById(this.itemTmp + "2");
      if (!this.isInViewport2(element_to_validate1, element_to_validate2)) {
        element_to_scroll_to.scrollIntoView({
          alignToTop: true,
          behavior: "smooth",
        });
      }

      this.myTimeout = setTimeout(this.changerItemCourant, 500);
    },
    sePlacerSurItemX() {
      if (this.myTimeout2) {
        clearTimeout(this.myTimeout2);
      }

      var element_to_validate1 = document.getElementById("contenu2");
      var element_to_validate2 = document.getElementById("contenu3");
      var element_to_scroll_to0 = document.getElementById(this.itemArchive+"0"); // à cause de la barre en fixed
      var element_to_scroll_to = document.getElementById(this.itemTmp);


        // à cause de la barre en fixed
        if  (this.items[this.itemTmp]) {
            if (!this.isInViewport2(element_to_validate1, element_to_validate2)) {
                if (
                  this.itemTmp == "passeport" ||
                  this.itemTmp == "portfolio" ||
                  this.itemTmp == "fenetre"
                ) {
                  element_to_scroll_to0.scrollIntoView({
                    alignToTop: true,
                    behavior: "smooth",
                  });
                }
                else {
                  element_to_validate1.scrollIntoView({
                    alignToTop: true,
                    behavior: "smooth",
                  });
                }
            }
        }
        else {
            if (!this.isInViewport(element_to_scroll_to0)) {
                  element_to_scroll_to0.scrollIntoView({
                    alignToTop: true,
                    behavior: "smooth",
                  });
            }
        }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
.addeoSmall {
  #outils {
    &.markOrientest {
      .containerAffichage {
        h3 {
          font-size: 1.1em;
          width: 90%;
          max-width: 500px;
        }
      }
    }
    #items {
      div.html {
        width: 90%;
        max-width: 980px;
        padding: 0 0 0 10%;
        text-align: left;

        &.pasVisible {
          margin: 1em auto 2em;
          padding-top: 2em;
          padding-bottom: 2em;
          font-size: 1.2em;
          line-height: 1.2;
          position: absolute;
          visibility: hidden;
        }

        p {
          margin: 0 0 0.8em 0;
          padding-left: 2em;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        span.retourLigne {
          display: block !important;
          position: relative;
          padding: 0;
          margin: 0;
          width: 0;
          height: 0;
        }
        .fa {
          font-size: 1.2em;
          color: #f3c935;
          margin-left: -1.8em;
          float: left;
        }
      }

      .contenu div.html,
      div.html {
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
        padding-left: 0;
      }

      .item {
        .fond {
          &.cliquable {
            display: block !important;
          }
        }
      }
    }
  }
}

.addeoMedium {
  #outils {
    #items {
      .item {
        max-width: 310px;
        width: 32%;

        .legende span {
          font-size: 1.8vw;
          @media screen and (min-width: 900px) {
            font-size: 1em;
          }
        }
      }

      .contenu > div {
        font-size: 0.8em;
        /*font-size:1.85vw ;*/
        line-height: 1.4;
        &.barre {
          /*
                        margin-right:10%;
                        width:80%;
                        */
          /*min-height: 220px;                                            */
        }

        @media screen and (min-width: 800px) {
          /*font-size:0.95em ;*/
          &.barre {
            min-height: 240px;
          }
        }

        span.retourLigne {
          display: inline !important;
          position: relative;
          padding: 0;
        }
      }
    }
  }
}

.addeoLarge,
.addeoSmall {
  #outils {
    #items {
      .contenu > div {
        line-height: 1.2;
      }
    }
  }
}
.addeoLarge {
  #outils {
    #items {
      .item {
        width: 30%;
      }
    }
  }
}

.addeoLarge,
.addeoMedium {
  #outils {
    width: 100%;
    height: auto;
    background-color: transparent;

    #items {
      .item {
        &.haut {
          .legende {
            top: 8%;
          }
        }
      }
    }
  }
}

#outils {
  width: 100%;
  height: auto;
  background-color: transparent;
  &.markOrientest {
    margin-top: 0;
    padding-top: 4em;
    // padding-bottom: 2em;
    /*
            background-color: #E1F7F5;
            border-top: 10px solid #BFEEEA;
            border-bottom: 10px solid #BFEEEA;
*/
    .containerAffichage {
      margin-top: 0;
      h3 {
        color: #356a72;
        font-size: 150%;
        max-width: 680px;
        margin: 3em auto 2em;
      }
    }
  }

  #items {
    a.lienOutil {
      color: #006ea9;
      /*font-weight: 600;*/
      /*color:#4B3C8F;*/
      text-decoration: underline;
      &:hover, &:focus {
        color: #4b3c8f;
      }
    }
    .item {
      display: inline-block;
      width: 100%;
      max-width: 386px;
      cursor: pointer;
      overflow: hidden;

      img {
        max-width: 100%;
      }

      .fond {
        position: absolute;
        top: 0;
        left: 0;
        &.cliquable {
          top: 50%;
          left: 40px;
          transform: translateY(-50%);
          font-size: 30px;
          z-index: 105;
          display: none !important;
          opacity: 0.1;
          color: #4b3c8f;
          color: white;
          margin: 0;
          padding: 0;
          text-align: left;
          &.droite {
            left: unset;
            right: 40px;
            text-align: right;
          }
        }
      }

      .legende {
        position: absolute;
        width: 100%;
        top: 70%;
        left: 0;
        right: 0;
        height: 22%;
        background-color: #356974;
        color: white;
        vertical-align: middle;
        font-weight: 600;

        span,
        div {
          padding: 0 4em;
          /*
                        padding: 5% 15% 0;
                        display: inline-block;
                        */
          display: inline-block;
          position: absolute;
          text-align: center;
          left: 0px;
          max-width: 100%;
          width: 80%;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          /*padding: 0px;*/

          span.retourLigne {
            display: block !important;
            position: relative;
            padding: 0;
            margin: 0;
            width: 0;
            height: 0;
          }
          i {
            margin-left: 0.2em;
            margin-top: -0.21em;
            font-size: 140%;
            vertical-align: middle;
          }
        }
      }
      .legende.titre {
        /*
                    top:7%;
                    height:20%;
*/
        background-color: #f5ca01 !important;
        span {
          /*font-size:1em ;*/
          color: #356974;
        }
        .fond {
          &.cliquable {
            color: #356974;
          }
        }
      }

      .survol {
        display: none;
      }
      &:hover, &:focus {
        .fond {
          display: none;
        }
        .survol {
          display: block;
        }
        .legende {
          background-color: #4b3c8f;
        }
      }
    }
    .contenu {
      font-size: 1.2em;
      line-height: 1.2;
      width: 100%;
      max-width: 100%;
      vertical-align: middle;
      font-weight: normal;
      color: #356974;
      margin: 2em auto;

        &.avecBarre {
            background: no-repeat top center transparent url("~@/assets/img/pro/outils/barre.svg");
            padding-top: 2em;
        }

      div.barre {
        background: no-repeat bottom center transparent
          url("~@/assets/img/pro/outils/barre.svg");
        padding-bottom: 2em;
        > div {
          /*height:100%;*/
        }
      }

      div.html {
        width: 90%;
        max-width: 980px;
        padding: 0 0 0 10%;
        text-align: left;

        p {
          margin: 0 0 0.8em 0;
          padding-left: 2em;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        span.retourLigne {
          display: block !important;
          position: relative;
          padding: 0;
          margin: 0;
          width: 0;
          height: 0;
        }
        .fa {
          font-size: 1.2em;
          color: #f3c935;
          margin-left: -1.8em;
          float: left;
        }
      }

      &.contenu3 {
        padding-top: 10px;
        .barre {
          padding-bottom: 10px;
          min-height: 260px;
        }
        .html {
          /*min-height: 240px;*/
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding-right: 5%;
          width: 85%;
        }
        .pasVisible {
          margin: 2em auto;
          padding-top: 2em;
          padding-bottom: 2em;
          font-size: 1.2em;
          line-height: 1.2;
          position: absolute;
          visibility: hidden;
        }
      }
    }
    .contenu1 {
      margin-top: 1em;
    }
    .pourScroll {
        position:absolute;
        top:-80px;
      }
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(30%);
    opacity: 0;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slideX-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slideX-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slideX-fade-enter, .slideX-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(40%);
    opacity: 0;
  }
}
</style>
