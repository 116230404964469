<template>
  <div id="distance" v-bind:class="{ markOrientest: markOrientest }">
    <div class="containerAffichage">
      <h3>
        Assurez le suivi à distance de la réalisation du parcours professionnel
      </h3>
      <div id="items">
        <div class="item" id="reseauD" @click.stop="reinit('reseauD')">
            <span id="reseauD0" class="pourScroll"></span>
          <item-outil :isOpen="items.reseauD" :data="lesDatas" item="reseauD" />
        </div>
        <div id="reseauD1" v-if="itemCourant != 'reseauD' && addeoSmall">
          <div
            id="reseauD2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['reseauD']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenuD1"
            v-if="itemCourant == 'reseauD' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="reseauD1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div class="item" id="production" @click.stop="reinit('production')">
            <span id="production0" class="pourScroll"></span>
          <item-outil
            :isOpen="items.production"
            :data="lesDatas"
            item="production"
          />
        </div>
        <div id="production1" v-if="itemCourant != 'production' && addeoSmall">
          <div
            id="production2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['production']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenuD1"
            v-if="itemCourant == 'production' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="production1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div class="item" id="progres" @click.stop="reinit('progres')">
            <span id="progres0" class="pourScroll"></span>
          <item-outil :isOpen="items.progres" :data="lesDatas" item="progres" />
        </div>
        <div id="progres1" v-if="itemCourant != 'progres' && addeoSmall">
          <div
            id="progres2"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['progres']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div
            class="contenu contenuD1"
            v-if="itemCourant == 'progres' && addeoSmall"
          >
            <div class="barre">
              <div>
                <div
                  id="progres1"
                  class="html"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </div>
            </div>
          </div>
        </transition>

        <div
          id="contenuD2"
          class="contenu contenuD3"
          v-if="!voircontenuD2 && !addeoSmall"
        >
          <div
            id="contenuD3"
            class="pasVisible html"
            v-html="lesDatas.itemsCont['reseauD']"
          ></div>
        </div>
        <transition name="slideX-fade">
          <div id="contenuD2" class="contenu contenuD3 avecBarre" v-if="voircontenuD2">
            <div class="barre">
              <transition name="slideX-fade">
                <div
                  id="contenuD3"
                  class="html"
                  v-if="itemCourant == itemTmp"
                  v-html="lesDatas.itemsCont[itemCourant]"
                ></div>
              </transition>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Outil from "@/components/pro/outil/Outil.vue";
export default {
  name: "Outils",
  components: {
    "item-outil": Outil,
  },
  props: {},
  data() {
    return {
      myTimeout: null,
      myTimeout2: null,
      itemTmp: "",
      itemCourant: "",
      imagesMarkOrientest: {
        reseauD: "img/oe/pro/distance/reseau.png",
        production: "img/oe/pro/distance/productions.png",
        progres: "img/oe/pro/distance/progres.png",
      },
      imagesSMarkOrientest: {
        reseauD: "img/oe/pro/distance/reseau.png",
        production: "img/oe/pro/distance/productions.png",
        progres: "img/oe/pro/distance/progres.png",
      },
      items: { reseauD: false, production: false, progres: false },
      itemsTxt: {
        reseauD: "Favorisez la mise<br/>en réseau",
        production: "Consultez et commentez<br/>les productions",
        progres: "Supervisez<br/>les progrès",
      },
      itemsContMarkOrientest: {
        reseauD:
          "<p><i class='fa fa-star'></i>Créez des groupes de bénéficiaires pour les accompagner ou les faire collaborer</p><p><i class='fa fa-star'></i>Renforcez l'accompagnement d'un groupe avec plusieurs facilitateurs, si besoin, de structures différentes</p><p><i class='fa fa-star'></i>Bénéficiez d'un annuaire de contacts professionnels</p>",
        production:
          "<p><i class='fa fa-star'></i>Recevez des demandes d'avis de la part de vos bénéficiaires</p><p><i class='fa fa-star'></i>Négociez ce que vos bénéficiaires vous autorisent à consulter et commenter sur leurs comptes</p><p><i class='fa fa-star'></i>Échangez avec les autres bénéficiaires et facilitateurs autorisés</p>",
        progres:
          "<p><i class='fa fa-star'></i>Vérifiez l'avancement de chaque bénéficiaire grâce à un tableau de bord</p><p><i class='fa fa-star'></i>Consultez le journal de tous les messages de chaque compte que vous suivez</p><p><i class='fa fa-star'></i>Recevez des notifications à chaque apport</p>",
      },
      tmp: true,
    };
  },

  created() {
    this.itemTmp = "";
  },

  computed: {
    voircontenuD2() {
      return (
        (this.itemCourant != "" || this.itemTmp != "") &&
        (this.$parent.$parent.addeoLarge || this.addeoMedium)
      );
    },
    voircontenuD3() {
      return (
        (this.itemCourant != "" || this.itemTmp != "") &&
        (this.$parent.$parent.addeoLarge || this.addeoMedium)
      );
    },
    lesDatas() {
      var datas = new Object();
      if (!this.markOrientest) {
        datas.images = this.images;
        datas.imagesS = this.imagesS;
        datas.itemsCont = this.itemsCont;
      } else {
        datas.images = this.imagesMarkOrientest;
        datas.imagesS = this.imagesSMarkOrientest;
        datas.itemsCont = this.itemsContMarkOrientest;
        var urlFolios = "https://webfolios.grandtest.addeo.ovh";
        if (this.$parent.$parent.isPreProdApp) {
          urlFolios = "https://preprod.webfolios.orientest.fr";
        } else {
          if (this.$parent.$parent.isProdApp) {
            urlFolios = "https://webfolios.orientest.fr";
          }
        }
      }
      datas.items = this.items;
      datas.itemsTxt = this.itemsTxt;
      return datas;
    },
    addeoSmall() {
      return this.$parent.$parent.addeoSmall;
    },
    addeoMedium() {
      return this.$parent.$parent.addeoMedium;
    },
    addeoLarge() {
      return this.$parent.$parent.addeoLarge;
      //return(this.$parent.$parent.addeoLarge && !this.$parent.$parent.addeoXLarge);
    },
    markOrientest() {
      return this.$router.options.markOrientest;
    },
    /*
        addeoXLarge() {
            return(this.$parent.$parent.addeoLarge && this.$parent.$parent.addeoXLarge);
        }
        */
  },
  methods: {
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    isInViewport2(element1, element2) {
      const rect = element1.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      var h2 = rect2.bottom - rect2.top;
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect2.top + h2 <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect2.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    reinit(item) {
      item !== "reseauD" ? (this.items["reseauD"] = false) : null;
      item !== "production" ? (this.items["production"] = false) : null;
      item !== "pepiniere" ? (this.items["pepiniere"] = false) : null;
      item !== "progres" ? (this.items["progres"] = false) : null;
      item !== "ressources" ? (this.items["ressources"] = false) : null;
      item !== "coffre" ? (this.items["coffre"] = false) : null;

      this.items[item] = !this.items[item];

      this.itemTmp = item;
      if (this.itemCourant == "") {
        if (this.addeoSmall) {
          this.myTimeout2 = setTimeout(this.sePlacerSurItemS, 250);
        } else {
          this.changerItemCourant();
        }
      } else {
        this.itemCourant = "";

        if (this.addeoSmall) {
          this.myTimeout2 = setTimeout(this.sePlacerSurItemS, 250);
        } else {
          this.myTimeout = setTimeout(this.changerItemCourant, 250);
        }
      }

      // en dernier car on a pu refermer au dessus ...
      if (item !== "") {
        if (this.addeoLarge || this.addeoMedium) {
          this.myTimeout2 = setTimeout(this.sePlacerSurItemX, 50);
        }
      }
    },
    changerItemCourant() {
      if (this.myTimeout) {
        clearTimeout(this.myTimeout);
      }
      if (this.items[this.itemTmp]) {
        this.itemCourant = this.itemTmp;
      } else {
        this.itemCourant = "";
        this.itemTmp = "";
      }
    },
    sePlacerSurItemS() {
      if (this.myTimeout2) {
        clearTimeout(this.myTimeout2);
      }
      var element_to_scroll_to = document.getElementById(this.itemTmp);
      var element_to_validate1 = document.getElementById(this.itemTmp + "1");
      var element_to_validate2 = document.getElementById(this.itemTmp + "2");
      if (!this.isInViewport2(element_to_validate1, element_to_validate2)) {
        element_to_scroll_to.scrollIntoView({
          alignToTop: true,
          behavior: "smooth",
        });
      }

      this.myTimeout = setTimeout(this.changerItemCourant, 500);
    },
    sePlacerSurItemX() {
      if (this.myTimeout2) {
        clearTimeout(this.myTimeout2);
      }

      var element_to_validate1 = document.getElementById("contenuD2");
      var element_to_validate2 = document.getElementById("contenuD3");
      //var element_to_scroll_to = document.getElementById(this.itemTmp);
      var element_to_scroll_to = document.getElementById(this.itemTmp+"0"); // à cause de la barre en fixed

      if (!this.isInViewport2(element_to_validate1, element_to_validate2)) {
        if (
          this.itemTmp == "reseauD" ||
          this.itemTmp == "production" ||
          this.itemTmp == "progres"
        ) {
          element_to_scroll_to.scrollIntoView({
            alignToTop: true,
            behavior: "smooth",
          });
        }
          /* il n'y en a que 3
          else {
          element_to_scroll_to.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "smooth",
          });
        }
          */
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
.addeoSmall {
  #distance {
    &.markOrientest {
      .containerAffichage {
        h3 {
          font-size: 1.1em;
          width: 90%;
          max-width: 500px;
        }
      }
    }
    #items {
      div.html {
        width: 90%;
        max-width: 980px;
        padding: 0 0 0 10%;
        text-align: left;

        &.pasVisible {
          margin: 1em auto 2em;
          padding-top: 2em;
          padding-bottom: 2em;
          font-size: 1.2em;
          line-height: 1.2;
          position: absolute;
          visibility: hidden;
        }

        p {
          margin: 0 0 0.8em 0;
          padding-left: 2em;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        span.retourLigne {
          display: block !important;
          position: relative;
          padding: 0;
          margin: 0;
          width: 0;
          height: 0;
        }
        .fa {
          font-size: 1.2em;
          color: #f3c935;
          margin-left: -1.8em;
          float: left;
        }
      }

      .contenu div.html,
      div.html {
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
        padding-left: 0;
      }

      .item {
        .fond {
          &.cliquable {
            display: block !important;
          }
        }
      }
    }
  }
}

.addeoMedium {
  #distance {
    #items {
      .item {
        max-width: 310px;
        width: 32%;

        .legende span {
          font-size: 1.8vw;
          @media screen and (min-width: 900px) {
            font-size: 1em;
          }
        }
      }

      .contenu > div {
        font-size: 0.8em;
        /*font-size:1.85vw ;*/
        line-height: 1.4;
        &.barre {
          /*
                        margin-right:10%;
                        width:80%;
                        */
          /*min-height: 220px;                                            */
        }

        @media screen and (min-width: 800px) {
          /*font-size:0.95em ;*/
          &.barre {
            min-height: 240px;
          }
        }

        span.retourLigne {
          display: inline !important;
          position: relative;
          padding: 0;
        }
      }
    }
  }
}

.addeoLarge,
.addeoSmall {
  #distance {
    #items {
      .contenu > div {
        line-height: 1.2;
      }
    }
  }
}
.addeoLarge {
  #distance {
    #items {
      .item {
        width: 30%;
      }
    }
  }
}

.addeoLarge,
.addeoMedium {
  #distance {
    width: 100%;
    height: auto;
    background-color: transparent;

    #items {
      .item {
        &.haut {
          .legende {
            top: 8%;
          }
        }
      }
    }
  }
}

#distance {
  width: 100%;
  height: auto;
  background-color: transparent;
  &.markOrientest {
    margin-top: 0;
    /* padding-top:4em; */
    padding-bottom: 2em;
    /*
            background-color: #E1F7F5;
            border-top: 10px solid #BFEEEA;
            border-bottom: 10px solid #BFEEEA;
*/
    .containerAffichage {
      margin-top: 0;
      h3 {
        color: #356a72;
        font-size: 150%;
        max-width: 680px;
        margin: 0 auto 1em;
      }
    }
  }

  #items {
    a.lienOutil {
      color: #006ea9;
      /*font-weight: 600;*/
      /*color:#4B3C8F;*/
      text-decoration: underline;
      &:hover, &:focus {
        color: #4b3c8f;
      }
    }
    .item {
      display: inline-block;
      width: 100%;
      max-width: 386px;
      cursor: pointer;
      overflow: hidden;

      img {
        max-width: 100%;
      }

      .fond {
        position: absolute;
        top: 0;
        left: 0;
        &.cliquable {
          top: 50%;
          left: 40px;
          transform: translateY(-50%);
          font-size: 30px;
          z-index: 105;
          display: none !important;
          opacity: 0.1;
          color: #4b3c8f;
          color: white;
          margin: 0;
          padding: 0;
          text-align: left;
          &.droite {
            left: unset;
            right: 40px;
            text-align: right;
          }
        }
      }

      .legende {
        position: absolute;
        width: 100%;
        top: 70%;
        left: 0;
        right: 0;
        height: 22%;
        background-color: #356974;
        color: white;
        vertical-align: middle;
        font-weight: 600;

        span,
        div {
          padding: 0 4em;
          /*
                        padding: 5% 15% 0;
                        display: inline-block;
                        */
          display: inline-block;
          position: absolute;
          text-align: center;
          left: 0px;
          max-width: 100%;
          width: 80%;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          /*padding: 0px;*/

          span.retourLigne {
            display: block !important;
            position: relative;
            padding: 0;
            margin: 0;
            width: 0;
            height: 0;
          }
          i {
            margin-left: 0.2em;
            margin-top: -0.21em;
            font-size: 140%;
            vertical-align: middle;
          }
        }
      }
      .legende.titre {
        /*
                    top:7%;
                    height:20%;
*/
        background-color: #f5ca01 !important;
        span {
          /*font-size:1em ;*/
          color: #356974;
        }
        .fond {
          &.cliquable {
            color: #356974;
          }
        }
      }

      .survol {
        display: none;
      }
      &:hover, &:focus {
        .fond {
          display: none;
        }
        .survol {
          display: block;
        }
        .legende {
          background-color: #4b3c8f;
        }
      }
    }
    .contenu {
      font-size: 1.2em;
      line-height: 1.2;
      width: 100%;
      max-width: 100%;
      vertical-align: middle;
      font-weight: normal;
      color: #356974;
      margin: 0 auto 2em;
/*
      background-image: none;
      padding-top: 1em;
*/
        &.avecBarre {
            background: no-repeat top center transparent url("~@/assets/img/pro/outils/barre.svg");
            padding-top: 2em;
            margin-top: 2em;
        }

      div.barre {
        background: no-repeat bottom center transparent
          url("~@/assets/img/pro/outils/barre.svg");
        padding-bottom: 2em;
        > div {
          /*height:100%;*/
        }
      }

      div.html {
        width: 90%;
        max-width: 980px;
        padding: 0 0 0 10%;
        text-align: left;

        p {
          margin: 0 0 0.8em 0;
          padding-left: 2em;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        span.retourLigne {
          display: block !important;
          position: relative;
          padding: 0;
          margin: 0;
          width: 0;
          height: 0;
        }
        .fa {
          font-size: 1.2em;
          color: #f3c935;
          margin-left: -1.8em;
          float: left;
        }
      }

      &.contenuD3 {
        padding-top: 0;
        .barre {
          /*padding-bottom: 160px;*/
          padding-bottom: 80px;
          min-height: 200px;
        }
        .html {
          /*min-height: 240px;*/
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding-right: 5%;
          width: 85%;
        }
        .pasVisible {
          margin: 2em auto;
          padding-top: 2em;
          padding-bottom: 2em;
          font-size: 1.2em;
          line-height: 1.2;
          position: absolute;
          visibility: hidden;
        }
      }
    }
    .contenuD1 {
      margin-top: 0;
    }
    .pourScroll {
        position:absolute;
        top:-80px;
      }
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(30%);
    opacity: 0;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slideX-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slideX-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slideX-fade-enter, .slideX-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(40%);
    opacity: 0;
  }
}
</style>
