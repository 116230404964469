<template>
    <div id="avantagesPro" v-bind:class="{markOrientest : markOrientest}">
        <div class="containerAffichage">
            <h2 class="sousTitre" v-if="markOrientest"><span class="commeSousTitre">Pourquoi un compte PRO ?</span></h2>
            <h2 class="sousTitre" v-if="!markOrientest"><span class="commeSousTitre">Adoptez un compte PRO</span></h2>

            <div id="items" v-if="markOrientest">
                    <item-avantage :data="lesDatas" item="agenda" />
                    <item-avantage :data="lesDatas" item="ressources" />
                    <item-avantage :data="lesDatas" item="lettres" />
                    <item-avantage :data="lesDatas" item="actions" />
                    <item-avantage :data="lesDatas" item="accompagner" />
                    <item-avantage :data="lesDatas" item="communaute" />
<!--                    <item-avantage :data="lesDatas" item="compte" class="dernier"/> -->
            </div>

            <div id="items" v-if="!markOrientest">
                    <item-avantage :data="lesDatas" item="guichet" />
                    <item-avantage :data="lesDatas" item="ressources" />
                    <item-avantage :data="lesDatas" item="fiches" />
                    <item-avantage :data="lesDatas" item="messages" />
                    <item-avantage :data="lesDatas" item="agenda" />
                    <item-avantage :data="lesDatas" item="actions" class="dernier"/>
            </div>

        </div>
    </div>
</template>

<script>
    import Avantage from '@/components/pro/avantage/AvantagePro.vue'
export default {
    name: 'Avantages',
    components: {
        'item-avantage': Avantage,
        },
    props: {
    },
    data() {
        return {
            images : {"guichet":"img/pro/avantages/guichet.svg", "ressources":"img/pro/avantages/ressources.svg", "fiches":"img/pro/avantages/fiches.svg", "messages":"img/pro/avantages/messages.svg", "agenda":"img/pro/avantages/agenda.svg", "actions":"img/pro/avantages/actions.svg"},
            itemsTitre : {"guichet":"guichet", "ressources":"ressources", "fiches":"fiches", "messages":"messages", "agenda":"agenda", "actions":"À l'écoute"},
            itemsCont : {"guichet":"Un <span class='important'>guichet unique</span><span class='retourLigne'>&nbsp;</span>pour mieux connaître les métiers<span class='retourLigne'>&nbsp;</span>et les secteurs d’activité",
                         "ressources":"Une sélection<span class='retourLigne'>&nbsp;</span>de <span class='important'>ressources utiles</span><span class='retourLigne'>&nbsp;</span>à vos missions",
                         "fiches":"Des <span class='important'>fiches pratiques</span><span class='retourLigne'>&nbsp;</span>pour utiliser<span class='retourLigne'>&nbsp;</span>Lorfolio",
                         "messages":"Un <span class='important'>fil de messages</span><span class='retourLigne'>&nbsp;</span>dédié aux professionnels",
                         "agenda":"L’<span class='important'>agenda</span><span class='retourLigne'>&nbsp;</span>des rencontres réservées<span class='retourLigne'>&nbsp;</span>aux professionnels",
                         "actions":"L’accès au programme<span class='retourLigne'>&nbsp;</span>des <span class='important'>actions de professionnalisation</span>"},

            imagesOrientest : {"agenda":"img/oe/pro/avantages/agenda.svg", "ressources":"img/oe/pro/avantages/ressources.svg", "lettres":"img/oe/pro/avantages/lettresNews.svg", "actions":"img/oe/pro/avantages/actions.svg", "accompagner":"img/oe/pro/avantages/accompagner.svg", "communaute":"img/oe/pro/avantages/communaute.svg"/*, "compte":"img/oe/pro/avantages/compteoe.svg"*/},
            itemsTitreOrientest : {"agenda":"agenda", "ressources":"ressources", "lettres":"lettres", "actions":"À l'écoute", "accompagner":"accompagner", "communaute":"communaute"/*, "compte":"compte"*/},
            itemsContOrientest : {"agenda":"Un <span class='important'>agenda</span><span class='retourLigne'>&nbsp;</span>des événements pour<span class='retourLigne'>&nbsp;</span>les professionnels", "ressources":"Un espace de<span class='retourLigne'>&nbsp;</span><span class='important'>ressources utiles</span><span class='retourLigne'>&nbsp;</span>à vos missions","lettres":"Une <span class='important'>information</span><span class='retourLigne'>&nbsp;</span>personnalisée<span class='retourLigne'>&nbsp;</span>", "actions":"Un accès au programme<span class='retourLigne'>&nbsp;</span>des <span class='important'>actions de<span class='retourLigne'>&nbsp;</span>professionnalisation</span><span class='retourLigne'>&nbsp;</span>","accompagner":"Un ensemble d'outils<span class='retourLigne'>&nbsp;</span>pour <span class='important'>accompagner</span><span class='retourLigne'>&nbsp;</span>vos bénéficiaires<span class='retourLigne'>&nbsp;</span>","communaute":"Une communauté avec<span class='retourLigne'>&nbsp;</span>les <span class='important'>Rencontres PRO</span><span class='retourLigne'>&nbsp;</span>"/*, "compte":"Bénéficier d’un <span class='important'>compte</span> pour expérimenter le compagnon du parcours professionnel offert par la Région à toutes les personnes qui vivent en Grand Est"*/},
            liensOrientestTMP : {"actions":"actionsTMP", "accompagner":"accompagnerTMP", "communaute":"communauteTMP"},
            liensOrientest : {"actions":"actions", "accompagner":"accompagner", "communaute":"communaute"},
            tmp:false,
            thisGlobal:this
        }
    },

    computed: {
        lesDatas() {
            var datas = new Object();
            if (this.markOrientest) {
                datas.images= this.imagesOrientest;
                datas.itemsTitre= this.itemsTitreOrientest;
                datas.itemsCont= this.itemsContOrientest;
                datas.liensOrientest= this.liensOrientestTMP;
                if (this.$router.options.paramDevScroll) {
                    datas.liensOrientest= this.liensOrientest;
                }
            }
            else {
                datas.images= this.images;
                datas.itemsTitre= this.itemsTitre;
                datas.itemsCont= this.itemsCont;
            }
            return(datas);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
        /*,
        thisGlobal() {
            return(this);
        }
        */
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

.addeoSmall {
    #avantagesPro {
        #items {
            max-width: 95%;

            #avantage {
                width:100%;

                img {
                    max-height: 80px;
                }

                .contenu {
                        font-size:1.2em;
                    /*
                    font-size:2.4vw;
                    @media screen and (min-width: 800px) {
                        font-size:1.2em;
                    }
                    */
                }
            }
        }
    }
}

.addeoMedium {
    #avantagesPro {
        /*padding-top:3em;*/

        #items {
            max-width: 95%;

            #avantage {
                width:50%;

                img {
                    max-height: 80px;
                }

                .contenu {
                    font-size:2.4vw;
                    @media screen and (min-width: 800px) {
                        font-size:1.2em;
                    }
                }
            }
        }
    }

    &.addeoLargeLow {
        #avantagesPro {
            &.markOrientest {
                padding-top: 2em;
            }
        }
    }
}

#app {
    #avantagesPro {
        z-index:2;
        div.containerAffichage {
            margin-top: 3%;
        }
    }
}
    #avantagesPro {
        width:100%;
        height:auto;
        /*background: no-repeat center top #EDF2F5 url("~@/assets/img/decoupage.png");*/
        text-align: center;
        padding:1em 0 2em;

        &.markOrientest {
            /*background-color:#FCF0C7;*/
        }

        .dernier {
            .item {
                background-image: none;
            }
        }

        #items {
            padding-top:1em;
            max-width: 90%;
            margin:0 auto;
            text-align: center;
        }

        #avantage {
            display:inline-block;
            width:33.3%;
            min-width:291px;
            margin: 0 auto;
            vertical-align: top;
        }
        .item {
            display:inline-block;
            width:95%;
            margin:2em auto 0;
            text-align: center;
            padding-bottom:2em;


            img {
                display:block;
                width:auto;
                max-height: 100px;
                margin: 0 auto 1em;
            }



            .contenu {
                text-align: center;
                width:100%;
                font-weight: normal;
                color:#4B3C8F;
                font-weight: 400;
                font-size:1.5vw;

                @media screen and (min-width: 1250px) {
                    font-size:1.2em ;
                }

                span {
                    line-height: 1.4;
                    .important {
                        font-weight: 600;
                    }
                    .retourLigne {
                        display: block !important;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        width: 0;
                        height: 0;
                    }
                }
                a {
                    font-size:90%;
                    text-decoration: underline;
                }
            }



        }


    }
</style>
