<template>
    <div id="inscription" :class="classInscription">
        <div v-if="markOrientest && !notop" class="top"></div>
        <div class="gauche"></div>
        <div class="droite"></div>
        <div class="contenu">
            <span v-if="markOrientest" class="titre">Pas encore de <span class="insecable">compte ?</span></span>
            <a
                @mouseover="hovered = true"
                @mouseleave="hovered = false"
                v-if="markOrientest" :href="urlInscriptionPro" class="bouton">
                Je crée mon compte
                <img v-if="hovered" src="../../assets/img/pro/compte/logoOrientestPro.svg" alt="OrientEst PRO" class="centrer" />
                <img v-if="!hovered" src="../../assets/img/pro/compte/logoOrientestProBlanc.svg" alt="OrientEst PRO" class="centrer" />
            </a>

            <span v-if="!markOrientest" class="titre">Pas encore de compte Lorfolio <span class="insecable">Pro ?</span></span>
            <a v-if="!markOrientest" :href="urlInscriptionPro" class="bouton">Demander un compte "PRO"</a>
        </div>
  </div>
</template>

<script>
export default {
    name: 'Inscription',
    props: {
        notop: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hovered:false,
            tmp:true,
            imgs:[]
        }
    },
    created() {
        this.preloadImages([
            "/img/oe/pro/cache/illusDroite.png",
            "/img/oe/pro/cache/illusGauche.png"
            ], function(){
                console.log('All images were loaded');
                //this.ready=1;
            });
    },
    computed: {
        classInscription() {
            return {"notop": this.notop}
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        urlInscriptionPro() {
            let leDomaine = window.location.hostname;
            if (this.markOrientest) {
                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription_pro"); // URL de test
                }

                if (leDomaine.indexOf("grandtest") >= 0) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription_pro"); // URL de test
                }

                if ( (leDomaine != "monorientest.fr") && (leDomaine != "www.monorientest.fr")
                    && (leDomaine != "mon.orientest.fr") && (leDomaine != "www.mon.orientest.fr")
                    && (leDomaine != "orientest.pro") && (leDomaine != "www.orientest.pro")
                    && (leDomaine.indexOf("localhost")< 0) ) {
                    return("https://preprod.monorientest.fr/utilisateurs/inscription_pro"); // URL de preprod
                }
                else {
                     if ((leDomaine == "mon.orientest.fr") || (leDomaine == "www.mon.orientest.fr")
                         || (leDomaine == "monorientest.fr") || (leDomaine == "www.monorientest.fr")
                         || (leDomaine == "orientest.pro") || (leDomaine == "www.orientest.pro")) {
                        return('https://mon.orientest.fr/utilisateurs/inscription_pro') // URL de prod
                     }
                }
            }
            else {
                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription_pro"); // URL de test
                }

                if (leDomaine.indexOf("grandtest") >= 0) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription_pro"); // URL de test
                }

                if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                    return("https://preprod.lorfolio.fr/utilisateurs/inscription_pro"); // URL de preprod
                }
                else {
                     if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                        return('https://mon.lorfolio.fr/utilisateurs/inscription_pro') // URL de prod
                     }
                }
            }
            return ''
        }
    },

    methods: {
        preloadImages(urls, allImagesLoadedCallback){
            var thisObj = this;
            var loadedCounter = 0;
            var toBeLoadedNumber = urls.length;
            var counter = 0;
            urls.forEach(function(url){
                thisObj.preloadImage(url, counter, function(){
                    loadedCounter++;
                    // console.log('Number of loaded images: ' + loadedCounter);
                    if(loadedCounter == toBeLoadedNumber){
                        allImagesLoadedCallback();
                    }
                });
                counter++;
            });
        },
        preloadImage(url, counter, anImageLoadedCallback){
            var img = new Image();
            img.onload = anImageLoadedCallback;
            img.src = url;
            this.imgs.push = img;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #inscription {
        width:90%;
        height:auto;
        background: no-repeat center bottom #F4CE45 none;
        padding:8em 5% 8em ;
        &.notop {
            /*padding: 3em 5% 8em;*/
        }
        @media screen and (max-width: 740px) {
            width:98%;
            padding:4em 1% ;
        }

        .top {
            background: no-repeat center top #F4CE45  url("~@/assets/img/oe/pro/decoupage.png");
            min-height:50px;
            position:absolute;
            width:100%;
            top:0;
            left:0;
        }

        span {
            text-transform: uppercase;
        }

        .contenu {
            .titre {
                display:block;
                color: #4B3C8F;
                margin-bottom: 2em;
                font-weight: bold;
                font-size:1.2em;
            }
            a.bouton {
                text-transform: uppercase;
                display:inline-block;
                border: 1px solid #356A73;
                background-color: #356A73;
                color: rgba(255,255,255,0.8);
                padding: 1.2em 4.9em;
                font-size: 1em;
                font-weight: 600;

                img {
                    margin-top: 10px;
                }

                &:hover {
                    /*opacity: 0.5;*/
                    background-color: white;
                    color: #356A73;
                }
                &:focus {
                    opacity: 0.5;
                }
            }

            @media screen and (min-width: 741px) and (max-width: 1100px) {
                margin : 0 auto 0 0;
                max-width: 560px;
                @media screen and (min-width: 960px) {
                    max-width: 700px;
                }
            }
        }

        .gauche, .droite {
            position:absolute;
            bottom:0;
            width:50%;
        }
        .gauche {
            left:0;
            /*background: no-repeat left bottom transparent url("~@/assets/img/pro/inscription/illusGauche.png");*/
            background: no-repeat left bottom transparent url("/img/oe/pro/cache/illusGauche.png");
            min-height: 600px;
            @media screen and (max-width: 1799px) {
                max-width: 28%;
                background-position: right bottom;
            }
            @media screen and (max-width: 1600px) {
                    max-width: 31%;
            }
            @media screen and (max-width: 1100px) {
                display:none;
            }
        }
        .droite {
            right:0;
            /*background: no-repeat center bottom transparent url("~@/assets/img/pro/inscription/illusDroite.png");*/
            background: no-repeat center bottom transparent url("/img/oe/pro/cache/illusDroite.png");
            min-height: 600px;
            @media screen and (max-width: 1799px) {
                    max-width: 30%;
                    background-position: left bottom;
                }
            @media screen and (max-width: 740px) {
                    display:none;
                }
        }
    }
</style>
